import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConvertUnit } from './../unit-converter';
import { BMR } from './../bmr';

@Component({
  selector: 'app-bmr-form',
  templateUrl: './bmr-form.component.html',
  styleUrls: ['./bmr-form.component.css']
})
export class BmrFormComponent implements OnInit {

  metric = true;
  male = true;

  sliderValue: number = 0;
  sliderMin = 0;
  sliderMax = 120;

  sliderWeightValue: number = 0;
  sliderWeightMin = 0;
  sliderWeightMax = 250;

  sliderHeightValue: number = 0;
  sliderHeightMin = 0;
  sliderHeightMax = 230;

  sliderInchValue: number = 0;
  sliderInchMin = 0;
  sliderInchMax = 20;

  calorieRequirement = 0;

  weightUnits = 'kg';
  heightUnits = 'cm';

  bmrForm = new FormGroup({
    age: new FormControl('', [Validators.required]),
    weight: new FormControl('', [Validators.required]),
    height: new FormControl('', [Validators.required]),
    inches: new FormControl('', [Validators.required])
  });

  constructor() { }

  ngOnInit() {
  }

  toggleMetric() {
    this.metric = true;
    this.heightUnits = 'cm';
    this.weightUnits = 'kg';
  }

  toggleImperial() {
    this.metric = false;
    this.heightUnits = 'feet';
    this.weightUnits = 'pounds';
    this.sliderHeightMax = 10;
    this.sliderInchMax = 12;
    this.sliderWeightMax = 500;
  }

  toggleMale() {
    this.male = true;
    this.onSubmit();
  }

  toggleFemale() {
    this.male = false;
    this.onSubmit();
  }

  updateSliderValue(value: number) {
    this.sliderValue = value
  }

  updateSliderHeightValue(value: number) {
    this.sliderHeightValue = value
  }

  updateSliderWeightValue(value: number) {
    this.sliderWeightValue = value
  }

  updateSliderInchValue(value: number) {
    this.sliderInchValue = value
  }

  

  onSubmit() {

    let {age, weight, height, inches} = this.bmrForm.value; 

    if (!this.metric) {
      let convert = new ConvertUnit();
      height = convert.feetToCm(height, inches);
      weight = convert.poundsToKg(weight); 
    }

    const gender = this.male === true ? 'male' : 'female';
    const bmr = new BMR(age, weight, gender ,height);
    this.calorieRequirement = bmr.mifflinStJeor();

  }
}
