export class ConvertUnit {

   feetToCm(feet, inches) {

      if (typeof(feet) === 'string') {
         feet = parseInt(feet);
      }

      if(typeof(inches) === 'string') {
         inches = parseInt(inches);
      }
 
      const feetToInches = feet * 12;
      const totalInches = feetToInches + inches;
      const cm = totalInches * 2.540;
      return cm
   }

   poundsToKg(lbs: number) {

      if (typeof(lbs) === 'string') {
         lbs = parseInt(lbs);
      }

      return lbs/2.205;
   }

}