export class BMR {

   age;
   weight;
   gender;
   height;

   constructor(age: number, weight: number, gender: string, height: number) {
      this.age = age;
      this.weight = weight;
      this.gender = gender;
      this.height = height;
   }

   mifflinStJeor() {
      if (this.gender === 'male') {
         return Math.floor((10 * this.weight) + (6.25 * this.height) - (5 * this.age) + 5);
      } else {
         return Math.floor((10 * this.weight) + (6.25 * this.height) - (5 * this.age) - 161);
      }
   }

   harrisonBenedict(): number {
      if (this.gender === 'male') {
         return Math.floor(88.362 + (13.397 * this.weight) + (4.799 * this.height) - (5.677 * this.age));
      } else {
         return Math.floor(447.593 + (9.247 * this.weight) + (3.098 * this.height) - (4.330 * this.age));
      }
   }

   averageBMR() {
      let mifflin = this.mifflinStJeor();
      let harrison = this.harrisonBenedict();
      return Math.floor((mifflin + harrison) / 2);
   }

}